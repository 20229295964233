import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import React, { ReactNode } from "react";
import Folder from "../../model/Folder";
import EditFolder from "../folder/EditFolder";
import ShareModal from "./ShareModal";
import ManageAccessModal from "./ManageAccessModal";
import { HiFolder } from "react-icons/hi";
import { UpdateFolderAccessDto } from "../../model/UpdateFolderAccessDto";
import FolderAccessInfoDto from "../../model/FolderAcessInfoDto";
import ManageAccessModalTitle from "../folder/ManageAccessModalTitle";

export const openDeleteModal = (isMobile: boolean, onConfirm) =>
  modals.openConfirmModal({
    title: "Delete folder",
    centered: true,
    overlayProps: {
      backgroundOpacity: 0.55,
      blur: 3,
    },
    children: (
      <>
        <Text size="sm">Are you sure you want to delete this folder?</Text>
        <Text c={"dimmed"} size="sm" mt="sm">
          This action is irreversible and cannot be undone. 
          You will lose all files and subfolders inside this folder.
        </Text>
      </>
    ),
    labels: { confirm: "Delete folder", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onCancel: () => console.log("Cancel"),
    onConfirm: onConfirm,
    fullScreen: isMobile,
  });

export const openModal = (isMobile: boolean) =>
  modals.openConfirmModal({
    title: "Please confirm your action",
    children: (
      <Text size="sm">
        This action is so important that you are required to confirm it with a
        modal. Please click one of these buttons to proceed.
      </Text>
    ),
    labels: { confirm: "Confirm", cancel: "Cancel" },
    onCancel: () => console.log("Cancel"),
    onConfirm: () => console.log("Confirmed"),
    fullScreen: isMobile,
  });

export const openLogoutModal = (onConfirm) =>
  modals.openConfirmModal({
    title: "Logout",
    centered: true,
    overlayProps: {
      backgroundOpacity: 0.55,
      blur: 3,
    },
    children: (
      <Text size="sm">
        Are you sure you want to logout? All pending uploads will be cancelled.
      </Text>
    ),
    labels: { confirm: "Logout", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onCancel: () => {},
    onConfirm: onConfirm,
  });

export const openFileUploadModal = (
  renderContent: () => ReactNode,
  isMobile: boolean
) =>
  modals.openConfirmModal({
    lockScroll: true,
    title: "Upload files",
    children: <div className="overflow-auto">{renderContent()}</div>,
    labels: { confirm: "Upload", cancel: "Cancel" },
    onCancel: () => console.log("Cancel upload"),
    onConfirm: () => console.log("Start upload"),
    fullScreen: isMobile,
  });

export const openShareModal = (
  folder: Folder,
  getFolderAccessInfo: (folderId: string) => Promise<FolderAccessInfoDto>,
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>,
  isMobile: boolean
) => {
  modals.open({
    title: "Share",
    children: (
      <ShareModal
        folder={folder}
        getFolderAccessInfo={getFolderAccessInfo}
        updateFolderAccess={updateFolderAccess}
      ></ShareModal>
    ),
    fullScreen: isMobile,
  });
};

export const openManageAccessModal = (
  folder: Folder,
  getFolderAccessInfo: (folderId: string) => Promise<FolderAccessInfoDto>,
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>,
  isMobile: boolean
) => {
  modals.open({
    title: <ManageAccessModalTitle folder={folder}></ManageAccessModalTitle>,
    children: (
      <ManageAccessModal
        folder={folder}
        getFolderAccessInfo={getFolderAccessInfo}
        updateFolderAccess={updateFolderAccess}
      ></ManageAccessModal>
    ),
    fullScreen: isMobile,
    size: "lg",
  });
};

export const openEmailVerificationModal = (email: string) => {
  modals.open({
    title: "Verify your email",
    children: (
      <div>
        <Text size="sm">
          Please check your email to verify your account before logging in.
        </Text>
        <Text size="sm" mt="md">
          {email}
        </Text>
      </div>
    ),
  });
};
