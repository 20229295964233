import React from "react";
import {
  Button,
  Container,
  Group,
  Switch,
  Text,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMantineColorScheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export default function Settings() {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const computedColorScheme = useComputedColorScheme("dark");

  const settingsSections = [
    {
      title: "General",
      description: null,
      items: [
        {
          title: "Dark mode",
          description: "Toggle dark mode for all app",
          action: () => {
            setColorScheme(computedColorScheme === "dark" ? "light" : "dark");
          },
        },
      ],
    },
    {
      title: "Gallery",
      description: "Image visualization and gallery settings",
      items: [
        {
          title: "Navigation buttons on the bottom",
          description: "Show navigation buttons on the bottom of the gallery",
          action: () => {},
          disabled: true,
        },
        {
          title: "Thumbnails",
          description: "Show thumbnails on the bottom of the gallery",
          action: () => {},
          disabled: true,
        },
        {
          title: "Backdrop click to close",
          description: "Close the gallery when clicking on the backdrop",
          action: () => {},
          disabled: true,
        },
      ],
    },
  ];

  const mapItem = (item) => (
    <Group
      justify="space-between"
      className="item"
      wrap="nowrap"
      gap="xl"
      mb="sm"
      key={item.title}
    >
      <div>
        <Text>{item.title}</Text>
        <Text size="xs" c="dimmed">
          {item.description}
        </Text>
      </div>
      <Switch
        onLabel="ON"
        offLabel="OFF"
        className="switch"
        size="md"
        defaultChecked={computedColorScheme === "dark"}
        disabled={item["disabled"]}
        onChange={item.action}
      />
    </Group>
  );

  return (
    <Container
      className="w-full mx-auto"
      p={isMobile ? "md" : "xl"}
      maw={"40rem"}
    >
      <Text fz="xl" className="title" fw={500}>
        Settings
      </Text>

      {settingsSections.map((section) => (
        <div key={section.title} className="mt-6">
          <Text fz="lg" fw={500}>
            {section.title}
          </Text>
          <Text fz="md" c="dimmed" mb={"md"}>
            {section?.description}
          </Text>
          <div className="items">
            {section?.items.map((item) => mapItem(item))}
          </div>
        </div>
      ))}
    </Container>
  );
}
