import {
  Button,
  LoadingOverlay,
  Modal,
  Overlay,
  ScrollArea,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React, { useEffect, useRef, useState } from "react";
import { HiArrowLeft, HiOutlineCloudDownload } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import useGlobalState, {
  globalFolder,
  globalProfile,
  globalToken,
} from "../../globalState";
import Folder from "../../model/Folder";
import useApi from "../../services/useApi";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import EditFolder from "../folder/EditFolder";
import Gallery from "../gallery/Gallery";
import { ActionId, MenuAction } from "../menu/menus";
import {
  openDeleteModal,
  openManageAccessModal,
  openShareModal,
} from "../modal/confirm";
import DevDebug from "./DevDebug";
import FileDropzone from "./FileDropzone";
import CreateFolderDto from "../../model/CreateFolderDto";
import FileEntity from "../../model/File";
import { RiDragDropLine } from "react-icons/ri";
import { useAuth } from "../../services/useAuth";
import { HiLockClosed } from "react-icons/hi";
import { getContentType } from "./ContentTypeUtils";

export default function Loader() {
  const [page, setPage] = useState(0);

  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [folder, setFolder] = useGlobalState(globalFolder);
  const [profile, setProfile] = useGlobalState(globalProfile);
  const navigate = useNavigate();

  const [folderId, setFolderId] = useState(
    null //"0d320dc4-0594-48eb-9f3d-a2e250bebede"
  ); // TODO: get from url

  const {
    fetchFiles,
    fetchFolder,
    fetchProfile,
    upsertFolder,
    updateFolderAccess,
    getFolderAccessInfo,
    deleteFile,
    deleteFolder,
    loading,
  } = useApi();
  const location = useLocation();

  const [isNewFolderModalOpened, openCloseNewFolder] = useDisclosure(false);
  const openNewFolderModal = openCloseNewFolder["open"];
  const closeNewFolderModal = openCloseNewFolder["close"];

  const [isEditFolderModalOpened, openCloseEditFolder] = useDisclosure(false);
  const openEditFolderModal = openCloseEditFolder["open"];
  const closeEditFolderModal = openCloseEditFolder["close"];

  const openRef = useRef<() => void>(null);

  const [isOverlay, setIsOverlay] = useState(false);
  const { pending, isSignedIn } = useAuth();

  const [isAccessDenied, setIsAccessDenied] = useState(false);

  const computedColorScheme = useComputedColorScheme("dark");
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  useEffect(() => {
    document.title = folder?.name ?? process.env.REACT_APP_APPNAME;
  }, [folder]);

  useEffect(() => {
    if (folderId == null) return;

    setIsLoading(true);

    fetchFolder(folderId, true).then((response: Folder) => {
      if (response === null) {
        setIsAccessDenied(true);
        setIsLoading(false);
        return;
      }
      console.log("fetchFolder", response);
      setFolders(response.folders);
      setFiles(response.files);
      setFolder(response);
      setIsLoading(false);
    });
  }, [folderId]);

  useEffect(() => {
    if (location == null) return;
    if (!pending && isSignedIn) getProfileAndParseLocation();
  }, [location, pending, isSignedIn]);

  // TODO: move to useEffect
  const getProfileAndParseLocation = () => {
    setIsLoading(true);

    if (!!profile) {
      parseLocationWithProfileResolved(profile);
      return;
    }

    fetchProfile().then((response) => {
      if (response === null) {
        navigate("/login");
        return;
      }
      setProfile(response);
      parseLocationWithProfileResolved(response);
    });
  };

  const parseLocationWithProfileResolved = (profileData: any) => {
    let currentLocationPath = location.pathname;

    if (currentLocationPath === "/home") {
      setFolderId(null);
      setFolder(null);
      setFolders([]);
      setFiles([]);

      // Set profile folders
      const allFolders = [];
      if (profileData?.folders?.length != 0) {
        allFolders.push(...profileData.folders);
      }
      if (profileData?.sharedFolders?.length != 0) {
        profileData.sharedFolders.forEach((f) => (f.type = "SHARED"));
        allFolders.push(...profileData.sharedFolders);
      }
      setFolders(allFolders);
      setIsLoading(false);

      return;
    }

    if (currentLocationPath.endsWith("/")) {
      currentLocationPath = currentLocationPath.substring(
        0,
        currentLocationPath.length - 1
      );
      navigate(currentLocationPath);
      return;
    }

    setFolders([]);
    setFiles([]);
    setPage(0);

    const pathNames = currentLocationPath.split("/").splice(2).reverse();
    const folderId = pathNames[0];

    setFolderId(folderId);

    setIsLoading(false);
  };

  const loadMore = () => {
    console.log("Load more triggered");
    if (isLoading) return;
    setIsLoading(true);
    fetchFiles(folder.id, page + 1).then((response) => {
      setFiles([...files, ...response]);
      // TODO: save data as global state to allow page changes
      setIsLoading(false);
      setPage(page + 1);
    });
  };

  const onFolderClick = (folder: Folder) => {
    navigate("/browse/" + folder.id);
  };

  const renderLoadMoreButton = () => {
    const hasNoFiles = files.length == 0;
    const isFirstLoading = isLoading && folderId == null;
    const isLastPage = files.length % 30 !== 0;

    if (isLastPage || hasNoFiles || isFirstLoading) {
      return <></>;
    }

    return (
      <div className="flex justify-center p-4">
        <Button
          onClick={loadMore}
          variant="default"
          leftSection={<HiOutlineCloudDownload />}
          loading={isLoading}
        >
          Load more
        </Button>
      </div>
    );
  };

  // Function to handle sharing the current photo
  const sharePhoto = async (photoUrl, photoName) => {
    try {
      // Fetch the image as a blob using the fetch API
      const response = await fetch(photoUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a File from the blob
      const file = new File([blob], photoName, {
        type: getContentType(photoName),
      });

      // Check if the browser supports the navigator share API
      if (navigator.share) {
        await navigator.share({
          title: "Check out this photo!",
          text: "I wanted to share this photo with you.",
          files: [file], // Sharing the file directly
        });
        console.log("Photo shared successfully!");
      } else {
        console.error("Web Share API not supported on this browser.");
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing photo:", error);
    }
  };

  const downloadPhoto = async (photoUrl, photoName) => {
    try {
      // Fetch the image as a blob using the fetch API
      const response = await fetch(photoUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();

      // Create a URL for the blob
      const blobUrl = URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = photoName; // Set the download attribute with the desired file name

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up: remove the link and revoke the blob URL
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);

      console.log("Photo downloaded successfully!");
    } catch (error) {
      console.error("Error downloading photo:", error);
    }
  };

  const onActionClick = (actionConfig: MenuAction, ctx: any) => {
    console.log("Loader.onActionClick", actionConfig);

    if (actionConfig.id === ActionId.SHARE) {
      if (!ctx.folder) return;
      openShareModal(
        ctx.folder,
        getFolderAccessInfo,
        updateFolderAccess,
        isMobile
      );
    }

    if (actionConfig.id === ActionId.SHARE_FILE) {
      console.log(ctx);
      if (!ctx.file) return;
      sharePhoto(ctx.file.url, ctx.file.name);
    }

    if (actionConfig.id === ActionId.DOWNLOAD_FILE) {
      console.log(ctx);
      if (!ctx.file) return;
      downloadPhoto(ctx.file.url, ctx.file.name);
    }

    if (actionConfig.id === ActionId.MANAGE_ACCESS) {
      if (!ctx.folder) return;
      openManageAccessModal(
        ctx.folder,
        getFolderAccessInfo,
        updateFolderAccess,
        isMobile
      );
    }

    if (actionConfig.id === ActionId.UPLOAD_FILES) {
      openRef.current?.();
    }

    if (actionConfig.id === ActionId.DELETE_FILE) {
      if (!ctx.file) return;
      deleteFile(ctx.file.id).then((response) => {
        notifications.show({
          id: "file-deleted",
          color: "green",
          title: "File deleted succesfully",
          message: "The file and its contents have been deleted",
          autoClose: false,
        });
        // If current folder has been deleted, navigate to the parent
        if (ctx.file.folderId === folderId) {
          if (!ctx.file.parentFolderId) {
            navigate("/home");
          } else {
            navigate("/browse/" + ctx.file.parentFolderId);
          }
        } else {
          setFiles(files.filter((f: FileEntity) => f.id !== ctx.file.id));
        }
      });
    }

    // FIXME
    if (actionConfig.id === ActionId.UPDATE_FOLDER) {
      if (!ctx.folder) return;
      // openEditFolderModal(folder, profile.email);
      openEditFolderModal();
      setFolder(folder); // instant local update for quick feedback
      // TODO: update in backend and handle error rollback
    }

    if (actionConfig.id === ActionId.DELETE_FOLDER) {
      if (!ctx.folder) return;
      openDeleteModal(isMobile, () => {
        deleteFolder(ctx.folder.id).then((response) => {
          notifications.show({
            id: "folder-deleted",
            color: "green",
            title: "Folder deleted succesfully",
            message: "The folder and its contents have been deleted",
            autoClose: false,
          });
          // If current folder has been deleted, navigate to the parent
          if (ctx.folder.id === folderId) {
            if (!ctx.folder.parentId) {
              navigate("/home");
            } else {
              navigate("/browse/" + ctx.folder.parentId);
            }
          } else {
            setFolders(folders.filter((f: Folder) => f.id !== ctx.folder.id));
          }
        });
      });
    }

    // FIXME: Pass ctx.folder as the parent content where the new folder will be created
    if (actionConfig.id === ActionId.CREATE_FOLDER) {
      openNewFolderModal(); // Refactor as component or method
    }
  };

  const onNewFolderSubmit = (newFolder: Folder) => {
    upsertFolder(newFolder).then((response) => {
      setFolders(
        [...folders, newFolder].sort((a, b) => a.name.localeCompare(b.name))
      );

      closeNewFolderModal();
    });
  };

  const onEditFolderSubmit = (updatedFolder: Folder) => {
    upsertFolder(updatedFolder).then((response) => {
      setFolders(response.folders);
      setFolder(response);

      // Update profile folders
      if (!updatedFolder.parentId) {
        setProfile({
          ...profile,
          folders: profile.folders.map((f: Folder) =>
            f.id === updatedFolder.id ? response : f
          ),
        });
      }

      closeEditFolderModal();
    });
  };

  const onFilesUploaded = (
    uploadedFiles: FileEntity[],
    newFolders: CreateFolderDto[]
  ) => {
    const updatedFiles = [
      ...files,
      ...uploadedFiles.filter((f) => f.folderId === folder.id),
    ];
    const updatedFolders = [
      ...folders,
      ...newFolders.filter((f) => f.parentFolderId === folder.id),
    ];
    setFiles(updatedFiles);
    setFolders(updatedFolders);

    console.log("Uploaded files to load in Gallery: ", uploadedFiles);
    // TODO: sort files

    notifications.show({
      id: "upload-complete",
      color: "green",
      title: "Upload complete",
      message: "Your files were uploaded succesfully",
      autoClose: false,
    });
  };

  const onBreadcrumbNavigate = () => {
    setFolders([]);
    setFiles([]);
  };

  const onBackClick = () => {
    setIsAccessDenied(false);
    setFolders([]);
    setFiles([]);
    navigate(-1);
  };

  const onHomeClick = () => {
    setIsAccessDenied(false);
    setIsLoading(true);
    setFolders([]);
    setFiles([]);
    navigate("/home");
  };

  const renderEmptyFolderPlaceholder = () => {
    if (isLoading) return <></>;
    if (files?.length != 0) return <></>;
    if (folders?.length != 0) return <></>;

    const msg = "Drag and drop files or click to upload";

    return (
      <div
        className="w-full h-full absolute flex flex-col gap-2 items-center justify-center text-center p-4
      cursor-pointer select-none text-gray-500 hover:text-blue-400 active:text-blue-500"
        onClick={() => openRef.current?.()}
      >
        <RiDragDropLine className="text-5xl" />
        <div className="text-lg mt-4">{msg}</div>
      </div>
    );
  };

  // Handle access denied error
  if (!isLoading && isAccessDenied) {
    return (
      <div className="w-full h-full flex flex-col items-center justify-center text-center p-4">
        <HiLockClosed className="text-5xl mb-3"></HiLockClosed>
        <div className="text-2xl">Access denied</div>
        <div className="text-lg mt-4">
          You don't have permission to access this folder
        </div>
        <div className="flex justify-between gap-2">
          <Button
            onClick={() => onBackClick()}
            variant="default"
            className="mt-4"
          >
            <HiArrowLeft className="mr-2"></HiArrowLeft>
            Go back
          </Button>
          <Button
            onClick={() => onHomeClick()}
            variant="subtle"
            className="mt-4"
          >
            Home
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full items-center justify-center relative">
      {isOverlay && <Overlay color="#000" backgroundOpacity={0.45} blur={1} />}

      <FileDropzone onFilesUploaded={onFilesUploaded} openRef={openRef} />

      <Breadcrumb
        onActionClick={onActionClick}
        setIsLoaderOverlay={setIsOverlay}
        onNavigate={onBreadcrumbNavigate}
      ></Breadcrumb>

      <ScrollArea
        scrollbarSize={14}
        scrollHideDelay={1500}
        type="hover"
        className="w-full h-full relative"
      >
        <LoadingOverlay
          visible={files.length == 0 && isLoading}
          zIndex={400}
          overlayProps={{
            blur: "xl",
            color: computedColorScheme === "dark" ? "black" : "black",
            opacity: computedColorScheme === "dark" ? "70%" : "30%",
          }}
          loaderProps={{ color: "blue", type: "bars", size: "xl" }}
        />
        {files.length == 0 && isLoading && renderEmptyFolderPlaceholder()}
        <div className="flex flex-col pb-2 overflow-auto w-full">
          <Gallery
            folders={folders}
            files={files}
            loadMoreFn={loadMore}
            onFolderClickFn={onFolderClick}
            onActionClick={onActionClick}
          ></Gallery>
          {renderLoadMoreButton()}
        </div>
      </ScrollArea>

      <Modal
        opened={isNewFolderModalOpened}
        onClose={closeNewFolderModal}
        closeOnClickOutside={!loading}
        closeOnEscape={!loading}
        title="New folder"
      >
        <div className="">
          <EditFolder
            folder={null}
            parentFolder={folder}
            onSubmit={onNewFolderSubmit}
            loading={loading}
          ></EditFolder>
        </div>
      </Modal>

      <Modal
        opened={isEditFolderModalOpened}
        onClose={closeEditFolderModal}
        closeOnClickOutside={!loading}
        closeOnEscape={!loading}
        title="Edit folder"
      >
        <div className="">
          <EditFolder
            folder={folder}
            parentFolder={null}
            onSubmit={onEditFolderSubmit}
            loading={loading}
          ></EditFolder>
        </div>
      </Modal>
    </div>
  );
}
