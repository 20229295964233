import {
  Button,
  Container,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import { HiOutlineRefresh } from "react-icons/hi";

export default function Account() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isDisabled, setIsDisabled] = useState(false);

  const updateConfig = () => {
    console.log("update config");
    setIsDisabled(true);
  };

  return (
    <div>
      <Container
        className="w-full mx-auto"
        p={isMobile ? "md" : "xl"}
        maw={"30rem"}
      >
        <Text fz="lg" fw={500} mb="md">
          Load Balancer{" "}
        </Text>

        <Container className="flex flex-row gap-2 justify-between items-baseline mb-2" p={0}>
          <Text fz="md" c="dimmed" mb="sm">
            Lambda API
          </Text>
          <Switch size="lg" onLabel="ON" offLabel="OFF" />
        </Container>

        <Container className="flex flex-row gap-2 justify-between items-baseline mb-2" p={0}>
          <Text fz="md" c="dimmed" mb="sm">
            EC2 API
          </Text>
          <Switch size="lg" onLabel="ON" offLabel="OFF" />
        </Container>
      </Container>

      <Container
        p={isMobile ? "md" : "xl"}
        maw={"30rem"}
        className="flex flex-row gap-2 justify-between items-baseline mb-2"
      >
        <Button
          w={"100%"}
          className="w-full"
          leftSection={<HiOutlineRefresh />}
          onClick={() => {
            updateConfig();
          }}
          disabled={isDisabled}
          loading={isDisabled}
        >
          Update configuration
        </Button>
      </Container>
    </div>
  );
}
