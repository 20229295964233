import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Group,
  Paper,
  PaperProps,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { upperFirst, useMediaQuery, useToggle } from "@mantine/hooks";
import React from "react";
import { signInWithGooglePopup } from "../../utils/FirebaseUtils";
import { GoogleButton } from "./GoogleButton";
//import { TwitterButton } from './TwitterButton';
import { notifications } from "@mantine/notifications";
import { UserCredential } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import useApi from "../../services/useApi";
import { openEmailVerificationModal } from "../modal/confirm";
import AppLogo from "../static/AppLogo";
import { AppleButton } from "./AppleButton";
import { FacebookButton } from "./FacebookButton";

export default function AuthForm(props: PaperProps) {
  const navigate = useNavigate();
  const [type, toggle] = useToggle(["login", "register"]);
  const { setColorScheme, clearColorScheme } = useMantineColorScheme();
  const { login, register } = useApi();

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      terms: true,
    },

    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : "Invalid email"),
      password: (val) =>
        val.length <= 6
          ? "Password should include at least 6 characters"
          : null,
    },
  });

  const signInWithGoogle = async () => {
    const response: UserCredential = await signInWithGooglePopup();

    if (response) {
      const redirectUrl = new URLSearchParams(window.location.search).get(
        "next"
      );

      navigate(redirectUrl ?? "/home");
    }
  };

  const renderSocialLoginButtons = () => {
    const isPrivateEnv = process.env.REACT_APP_ENVIRONMENT === "private";

    if (isPrivateEnv) {
      return (
        <Stack dir="" mb="md" mt="md">
          <GoogleButton radius="xl" onClick={signInWithGoogle}>
            Google
          </GoogleButton>
        </Stack>
      );
    }

    return (
      <Stack dir="" mb="md" mt="md">
        <GoogleButton radius="md" onClick={signInWithGoogle}>
          Google
        </GoogleButton>
        <AppleButton radius="md" onClick={signInWithGoogle}>
          Apple
        </AppleButton>
        <FacebookButton radius="md" onClick={signInWithGoogle}>
          Facebook
        </FacebookButton>
      </Stack>
    );
  };

  const submitForm = async (type: string) => {
    if (type === "register") {
      const { name, email, password } = form.values;
      if (!email || !password) return;

      await register(name, email, password);

      // Email verification
      openEmailVerificationModal(form.values.email);

      toggle();
    } else {
      const { email, password } = form.values;
      if (!email || !password) return;

      const isValid = await login(email, password);

      if (!isValid) {
        notifications.show({
          id: "login-failed",
          color: "red",
          title: "Login failed",
          message: "Invalid email or password",
          autoClose: false,
        });
        return;
      }

      const redirectUrl = new URLSearchParams(window.location.search).get(
        "next"
      );

      navigate(redirectUrl ?? "/home");
    }
  };

  return (
    <Paper radius={0} p="xl" withBorder {...props}>
      <div className="flex items-center justify-center mb-6 select-none scale-150">
        <AppLogo size={30}></AppLogo>
        <Text size="lg" fw={500}>
          {process.env.REACT_APP_APPNAME}
        </Text>
      </div>

      <Text size="md" fw={500}>
        Login with
      </Text>

      {renderSocialLoginButtons()}

      <Divider label="Or continue with email" labelPosition="center" my="lg" />

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Stack>
          {type === "register" && (
            <TextInput
              label="Name"
              placeholder="Your name"
              value={form.values.name}
              onChange={(event) =>
                form.setFieldValue("name", event.currentTarget.value)
              }
              radius="md"
            />
          )}

          <TextInput
            required
            label="Email"
            placeholder="hello@email.com"
            value={form.values.email}
            onChange={(event) =>
              form.setFieldValue("email", event.currentTarget.value)
            }
            error={form.errors.email && "Invalid email"}
            radius="md"
          />

          <PasswordInput
            required
            label="Password"
            placeholder="Your password"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue("password", event.currentTarget.value)
            }
            error={
              form.errors.password &&
              "Password should include at least 6 characters"
            }
            radius="md"
          />

          {type === "register" && (
            <Checkbox
              label="I accept terms and conditions"
              checked={form.values.terms}
              onChange={(event) =>
                form.setFieldValue("terms", event.currentTarget.checked)
              }
            />
          )}
        </Stack>

        <Group justify="space-between" mt="xl">
          <Anchor
            component="button"
            type="button"
            c="dimmed"
            onClick={() => toggle()}
            size="xs"
          >
            {type === "register"
              ? "Already have an account? Login"
              : "Don't have an account? Register"}
          </Anchor>
          <Button type="submit" radius="md" onClick={() => submitForm(type)}>
            {upperFirst(type)}
          </Button>
        </Group>
      </form>
    </Paper>
  );
}
