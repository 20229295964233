import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAvaiZfkUJhi0OCpRxm-cPlCG-e7msMGNw",
    authDomain: "picnube-seed.firebaseapp.com",
    projectId: "picnube-seed",
    storageBucket: "picnube-seed.appspot.com",
    messagingSenderId: "325663212709",
    appId: "1:325663212709:web:8420e2776b637667cc394c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({   
    prompt : "select_account"
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
