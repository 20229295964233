import {
  createTheme,
  MantineColorsTuple,
  MantineProvider,
} from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// core styles are required for all packages
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";

import SignInPage from "./components/auth/SignInPage";
import Loader from "./components/loader/Loader";
import Settings from "./components/settings/Settings";

// other css files are required only if
// you are using components from the corresponding package
// import '@mantine/dates/styles.css';
import "@mantine/dropzone/styles.css";
import Account from "./components/account/Account";
import ErrorPage from "./components/error/ErrorPage";
import MapView from "./components/mapView/MapView";
import Admin from "./components/admin/Admin";
// import '@mantine/code-highlight/styles.css';
// ...

const myColor: MantineColorsTuple = [
  "#e7f9ff",
  "#d5effa",
  "#abddf2",
  "#7ecbea",
  "#5bbce4",
  "#45b2e0",
  "#38addf",
  "#2898c7",
  "#1787b2",
  "#00759e",
];

const theme = createTheme({
  /** Put your mantine theme override here */
  colors: { myColor },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" replace />,
  },
  {
    path: "/",
    element: <App />,
    //errorElement: <ErrorPage />,
    children: [
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "home",
        element: <Loader />,
      },
      {
        path: "map",
        element: <MapView />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
      {
        path: "browse",
        element: <Loader />,
        children: [
          {
            path: ":folder/*",
            //element: <Gallery />,
            // loader: folderLoader,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <SignInPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

root.render(
  <MantineProvider theme={theme} defaultColorScheme="dark">
    <Notifications />
    <ModalsProvider>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </ModalsProvider>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
