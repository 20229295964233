import { IconType } from "react-icons";
import { HiClock, HiCloudUpload, HiDownload, HiFolderAdd, HiInformationCircle, HiPencil, HiPlay, HiShare, HiTrash, HiUsers } from "react-icons/hi";
import { MdSettingsRemote } from "react-icons/md";

export enum ActionId {
    SHARE,
    MANAGE_ACCESS,
    CREATE_FOLDER,
    UPDATE_FOLDER,
    DELETE_FOLDER,
    DOWNLOAD_FILE,
    MODIFY_CREATION_DATE,
    RENAME_FILE,
    UPLOAD_FILES,
    DELETE_FILE,
    SHARE_FILE,
}

export interface MenuSection {
    name: string;
    actions: MenuAction[];
}

export interface MenuAction {
    id?: ActionId;
    name: string;
    icon: IconType;
    description?: string;
    elementProps?: any;
    onClick?: () => void;
    showIf?: (ctx: any, email: string) => boolean;
    disabledIf?: (ctx: any, email: string) => boolean;
}

export const LOADER_MENU_CONFIG: MenuSection[] = [
    {
        name: "Actions",
        actions: [
            {
                name: "Upload files",
                icon: HiCloudUpload,
                id: ActionId.UPLOAD_FILES,
                disabledIf: (ctx: any, email: string) => {
                    if (!ctx?.folder) return false;
                    return ctx?.folder?.accessLevel !== 'write';
                },
            },
            {
                name: "New folder",
                id: ActionId.CREATE_FOLDER,
                icon: HiFolderAdd,
                disabledIf: (ctx: any, email: string) => {
                    if (!ctx?.folder) return false;
                    return ctx?.folder?.accessLevel !== 'write';
                },
            },
            {
                name: "Edit folder",
                id: ActionId.UPDATE_FOLDER,
                icon: HiPencil,
                showIf: (ctx: any) => !!ctx?.folder?.id,
                disabledIf: (ctx: any, email: string) => {
                    if (!ctx?.folder) return false;
                    return ctx?.folder?.accessLevel !== 'write';
                },
            },
        ],
    },
    {
        name: "Share",
        actions: [
            {
                name: "Share",
                id: ActionId.SHARE,
                icon: HiShare,
                showIf: (ctx: any) => !!ctx?.folder?.id,
            },

            {
                name: "Manage permissions",
                id: ActionId.MANAGE_ACCESS,
                icon: HiUsers,
                showIf: (ctx: any) => !!ctx?.folder?.id,
                disabledIf: (ctx: any, email: string) => {
                    if (!ctx?.folder) return false;
                    return ctx?.folder?.accessLevel !== 'write';
                },
            },
        ],
    },
    {
        name: "Slideshow options",
        actions: [
            {
                name: "Play",
                icon: HiPlay,
                showIf: (ctx: any) => !!ctx?.folder?.id,
            },
            {
                name: "Remote control",
                icon: MdSettingsRemote,
                description: "Use other device to control playback",
                showIf: (ctx: any) => !!ctx?.folder?.id,
            },
        ],
    },
    {
        name: "Danger zone",
        actions: [
            {
                name: "Delete folder",
                id: ActionId.DELETE_FOLDER,
                elementProps: { color: "red" },
                icon: HiTrash,
                showIf: (ctx: any) => !!ctx?.folder?.id,
                disabledIf: (ctx: any, email: string) => {
                    return ctx?.folder?.owner !== email;
                }
            },
        ],
    },
];

export const FILE_OPTIONS_MENU_CONFIG: MenuSection[] = [
    {
        name: "Actions",
        actions: [
            {
                name: "View details",
                icon: HiInformationCircle,
            },
            {
                name: "Share",
                id: ActionId.SHARE_FILE,
                icon: HiShare,
            },
            {
                name: "Download",
                id: ActionId.DOWNLOAD_FILE,
                icon: HiDownload,
            },
        ],
    },
    {
        name: "Edit",
        actions: [
            {
                name: "Rename",
                id: ActionId.RENAME_FILE,
                icon: HiPencil,
            },
            {
                name: "Modify creation date",
                id: ActionId.MODIFY_CREATION_DATE,
                icon: HiClock,
            },
        ],
    },
    {
        name: "Danger zone",
        actions: [
            {
                name: "Delete file",
                id: ActionId.DELETE_FILE,
                elementProps: { color: "red" },
                icon: HiTrash,
            },
        ],
    },
];
