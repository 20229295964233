import { Button, ScrollArea, Tabs, useMantineTheme, Text } from "@mantine/core";
import * as React from "react";
import { useState } from "react";
import { MonthPicker, YearPicker } from "@mantine/dates";
import { useMediaQuery } from "@mantine/hooks";
import {
  HiCube,
  HiFolder,
  HiLocationMarker,
  HiOutlineX,
  HiOutlineZoomOut,
  HiUsers,
} from "react-icons/hi";
import Folder from "../../model/Folder";
import { MdFolder, MdFolderShared } from "react-icons/md";
import useGlobalState, { globalProfile } from "../../globalState";

const exampleMapPoints = [
  {
    name: "Vacaciones en Italia",
    date: "Julio 2023",
    folderId: "097dfdbe-bce9-40b4-9c6a-15af6fc4a1d5",
    latitude: 41.9028,
    longitude: 12.4964,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBDqp8vwc0CNjBsrvPBx8ChPQxMV6YVwbr6A&usqp=CAU",
  },
  {
    name: "Asturias - Jesús y Aitor",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Urriellu_desde_el_Pozo_de_La_Oracion.jpg/408px-Urriellu_desde_el_Pozo_de_La_Oracion.jpg",
  },
  {
    name: "1",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "2",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "3",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "4",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "5",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "6",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "7",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "8",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "9",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "10",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "11",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "12",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "13",
    date: "Junio 2024",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "14",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "15",
    date: "Junio 2024",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "16",
    date: "Junio 2024",
    folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "17",
    date: "Junio 2024",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "18",
    date: "Junio 2024",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "19",
    date: "Junio 2024",
    latitude: 43.23,
    longitude: -4.81,
  },
  {
    name: "20",
    date: "Junio 2024",
    latitude: 43.23,
    longitude: -4.81,
  },
];

function ControlPanel(props: {
  mapPoints: any[];
  onSelectMarker: (mapPoint: any) => void;
}) {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [valueMonthPicker, setValueMonthPicker] = useState<
    [Date | null, Date | null]
  >([null, null]);

  const [filterTab, setFilterTab] = useState<"year" | "month">("year");
  const [profile, setProfile] = useGlobalState(globalProfile);

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const onFilterTabChange = (value: string) => {
    setFilterTab(value as "year" | "month");
  };

  function renderMarkerIcon(mapPoint: any) {
    if (!!mapPoint.folderId) {
      return <HiFolder className="text-lg" />;
    }
    return <HiLocationMarker className="text-lg" />;
  }

  function areFiltersActive() {
    console.log("value", value[0], valueMonthPicker[0]);
    return value[0] || valueMonthPicker[0];
  }

  function renderSearchResults() {
    if (!areFiltersActive()) {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <div className="flex flex-col justify-center items-center gap-2">
            <HiOutlineZoomOut className="text-lg" />

            <Text>No results</Text>
            <Text size="sm">Select a time range to filter</Text>
          </div>
        </div>
      );
    }

    return (
      <ScrollArea
        scrollbarSize={14}
        scrollHideDelay={1500}
        type="hover"
        className="w-full max-h-96 overflow-auto flex flex-col"
      >
        {exampleMapPoints?.map((mapPoint, index) => (
          <div
            key={`map-point-${index}`}
            className="
            flex justify-start items-center gap-2 py-2 px-4 cursor-pointer 
            border-b border-grey-800 hover:text-blue-300 hover:bg-grey-900 
            relative
            "
            onClick={() => props.onSelectMarker(mapPoint)}
          >
            {renderMarkerIcon(mapPoint)}
            {mapPoint.name}
          </div>
        ))}
      </ScrollArea>
    );
  }

  return (
    <div className="flex flex-col w-full p-0 xs:flex-row">
      <div className={`flex gap-2 flex-col`}>
        <Tabs
          defaultValue="year"
          orientation={"horizontal"}
          onChange={(value) => onFilterTabChange(value)}
        >
          <Tabs.List justify="center" grow>
            <Tabs.Tab value="year">Year</Tabs.Tab>
            <Tabs.Tab value="month">Month</Tabs.Tab>
            <Button
              display={value[0] || valueMonthPicker[0] ? "flex" : "none"}
              color="red"
              variant="subtle"
              size="xs"
              leftSection={<HiOutlineX />}
              onClick={() => {
                setValue([null, null]);
                setValueMonthPicker([null, null]);
              }}
            >
              Clear
            </Button>
          </Tabs.List>
        </Tabs>
        <YearPicker
          hidden={filterTab !== "year"}
          type="range"
          value={value}
          onChange={setValue}
          className="m-auto xs:m-0"
        />
        <MonthPicker
          hidden={filterTab !== "month"}
          type="range"
          value={valueMonthPicker}
          onChange={setValueMonthPicker}
          className="m-auto xs:m-0"
        />
      </div>

      <div className="flex border-r border-grey-800"></div>

      <div className="flex flex-col gap-2 w-full">{renderSearchResults()}</div>
    </div>
  );
}

export default React.memo(ControlPanel);
