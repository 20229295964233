import React from "react";
import AuthForm from "./AuthForm";

function SignInPage() {
  return (
    <div
      className="h-full w-full flex justify-center items-center overflow-hidden"
      style={{
        backgroundImage: "url(login-background.jpeg)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        boxShadow: 'inset 0 0 0 50vw rgba(0,0,0,0.3)',
      }}
    >
      <AuthForm className="h-full w-full xs:h-fit xs:w-fit"></AuthForm>
    </div>
  );
}

export default SignInPage;
