import { Button, Divider, TextInput, Text } from "@mantine/core";
import React, { useState } from "react";
import Folder from "../../model/Folder";
import useGlobalState, { globalProfile } from "../../globalState";
import ManageFolderAccess from "./ManageFolderAccess";
import { HiSave } from "react-icons/hi";
import { MdSave } from "react-icons/md";

const initializeUpdatedFolder = (
  folder: Folder,
  parentFolder: Folder,
  profile: any
) => {
  if (!!folder) return folder;

  const uuid = crypto.randomUUID();

  // 1st level folder (no parent)
  if (!parentFolder) {
    return {
      id: uuid,
      parentId: null,
      name: "",
      owner: profile.email,
      readUsers: [profile.email],
      writeUsers: [profile.email],
      dateCreated: new Date(),
      folders: [],
      path: "",
    };
  }

  // 2nd level folder
  return {
    id: uuid,
    parentId: parentFolder?.id,
    name: "",
    owner: parentFolder?.owner, // TODO: add ownerEmail
    dateCreated: new Date(),
    folders: [],
    path: parentFolder?.path + `;${parentFolder?.id}:${parentFolder?.name}`,
  };
};

export default function EditFolder({
  folder,
  parentFolder,
  onSubmit,
  loading,
}) {
  const [profile, _] = useGlobalState(globalProfile);
  const [updatedFolder, setUpdatedFolder] = useState(
    initializeUpdatedFolder(folder, parentFolder, profile)
  );

  const updateFolderName = (name: string) => {
    setUpdatedFolder({
      ...updatedFolder,
      name: name,
      path: updatePath(name),
    });
  };

  const updatePath = (newFolderName: string) => {
    return (
      (!!parentFolder?.path ? `${parentFolder.path};` : "") +
      `${updatedFolder.id}:${newFolderName}`
    );
  };

  const onSaveClick = () => {
    // TODO: add validation for conflicting name with another folder

    if (!updatedFolder || updatedFolder.name.length === 0) return;

    onSubmit(updatedFolder);
  };

  const renderManageFolderAccess = () => {
    if (!!folder) return <></>;

    return <></>
    //return <ManageFolderAccess folder={updatedFolder}></ManageFolderAccess>;
  };

  return (
    <div className="flex flex-col gap-4">
      <TextInput
        data-autofocus
        label="Folder Name"
        placeholder="Travel to Japan"
        defaultValue={folder?.name}
        onChange={(event) => updateFolderName(event.currentTarget.value)}
        mt="md"
      />

      <div className="flex justify-end mt-4">
        <Button
          onClick={onSaveClick}
          variant="filled"
          loading={loading}
          leftSection={<MdSave />}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
