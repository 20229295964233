import { useState } from 'react';

import { notifications } from '@mantine/notifications';
import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import CreateFolderDto from '../model/CreateFolderDto';
import FileMetadata from '../model/FileMetadata';
import { FileMetadataUploadResponseDto } from '../model/FileMetadataUploadResponseDto';
import Folder from '../model/Folder';
import { useAuth } from './useAuth';
import { openEmailVerificationModal } from '../components/modal/confirm';
import { UpdateFolderAccessDto } from '../model/UpdateFolderAccessDto';

export enum APIError {
    NOT_FOUND = 'NOT_FOUND',
    ACCESS_DENIED = 'ACCESS_DENIED',
}

const useApi = () => {
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { auth, validateAuth } = useAuth();

    //const isDevEnv = process.env.NODE_ENV === 'development';
    //const API_BASE_URL = isDevEnv ? '' : `${process.env.REACT_APP_API_BASE_URL}/`
    const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`
    const API_PATH_PROFILE = API_BASE_URL + process.env.REACT_APP_API_PATH_PROFILE
    const API_PATH_FOLDERS = API_BASE_URL + process.env.REACT_APP_API_PATH_FOLDERS
    const API_PATH_UPLOAD = API_BASE_URL + process.env.REACT_APP_API_PATH_UPLOAD
    const API_PATH_FILES = API_BASE_URL + process.env.REACT_APP_API_PATH_FILES

    const buildHeaders = async () => {
        const token = await auth.currentUser.getIdToken();

        return {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    const fetchProfile = async () => {
        if (!validateAuth()) return;

        setLoading(true);
        const response: Response = await fetch(API_PATH_PROFILE,
            {
                method: 'GET',
                headers: await buildHeaders()
            });

        const resolvedData = await response.json();

        console.log("fetchProfile", resolvedData);

        // TODO: improve with custom page for email verification
        if (!response.ok && resolvedData.id === 'email_not_verified') {
            openEmailVerificationModal('');
            return null;
        }

        setLoading(false);
        return resolvedData;
    }

    const fetchFiles = async (folderId: string, page: number) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);
            const response: Response = await fetch(
                `${API_PATH_FILES}/search?` + new URLSearchParams({
                    folderId: folderId,
                    page: '' + page,
                }),
                {
                    method: 'GET',
                    headers: await buildHeaders()
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const fetchFolder = async (folderId: string, includeFiles: boolean) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}?` + new URLSearchParams({
                    includeFiles: '' + includeFiles,
                }),
                {
                    method: 'GET',
                    headers: await buildHeaders()
                }
            );

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();
            setLoading(false);

            return resolvedData;

        } catch (e) {
            console.error(e);
            console.log(e.message);
            setLoading(false);

            if (e.message === 'Folder not found') {
                return APIError.NOT_FOUND;
            }
            if (e.message === 'Access denied') {
                return APIError.ACCESS_DENIED;
            }

            return null;

        } finally {
            setLoading(false);
        }
    }

    const upsertFolder = async (folder: Folder): Promise<Folder> => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/upsert`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(folder)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const getFolderAccessInfo = async (folderId: string) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}/access`,
                {
                    method: 'GET',
                    headers: await buildHeaders()
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;
        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
        }
    }

    const updateFolderAccess = async (folderId: string, request: UpdateFolderAccessDto) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}/access`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(request)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;
        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;
        }
    }

    const deleteFolder = async (folderId: string) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FOLDERS}/${folderId}?`,
                {
                    method: 'DELETE',
                    headers: await buildHeaders()
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;
        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const deleteFile = async (fileId: string) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FILES}/${fileId}`,
                {
                    method: 'DELETE',
                    headers: await buildHeaders()
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;
        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while fetching data from the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    // TODO: Rename to uploadMetadata
    const uploadFiles = async (files: FileMetadata[], folders: CreateFolderDto[]): Promise<FileMetadataUploadResponseDto> => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const request = {
                files: files,
                folders: folders,
            }

            const response: Response = await fetch(
                `${API_PATH_UPLOAD}`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(request)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while uploading data to the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            //navigate('/login');
            return null;

        } finally {
            setLoading(false);
        }
    }

    const requestFilesUpload = async (filesUploadRequest: any) => {
        if (!validateAuth()) return;

        try {
            setLoading(true);

            const response: Response = await fetch(
                `${API_PATH_FILES}/upload-request`,
                {
                    method: 'POST',
                    headers: await buildHeaders(),
                    body: JSON.stringify(filesUploadRequest)
                }).catch(err => {
                    console.log(err);
                    return null;
                });

            if (!response?.ok) {
                throw new Error();
            }

            const resolvedData = await response.json();

            return resolvedData;

        } catch (e) {
            console.error(e);
            notifications.show({
                id: "error-unknown",
                color: "red",
                title: "Error while uploading data to the server",
                message: "Check your internet connection",
                autoClose: false,
            });
            //navigate('/login');
            return;

        } finally {
            setLoading(false);
        }
    }

    const login = async (email: string, password: string): Promise<boolean> => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password)

            const idToken = await userCredential.user.getIdToken();
            if (idToken) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    const register = async (name: string, email: string, password: string) => {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);

        await sendEmailVerification(auth.currentUser)

        console.log('Email sent, user account created:', userCredential.user);

        openEmailVerificationModal(email);
    }

    return {
        fetchProfile,
        fetchFiles,
        fetchFolder,
        upsertFolder,
        uploadFiles,
        requestFilesUpload,
        updateFolderAccess,
        getFolderAccessInfo,
        deleteFile,
        deleteFolder,
        login,
        register,
        loading,
    };
};

export default useApi;
