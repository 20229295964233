import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import { useAuth } from "./services/useAuth";

function App() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const navigate = useNavigate();
  const { validateAuth, user, pending } = useAuth();

  useEffect(() => {
    if (pending) return;
    if (!user) validateAuth();
  }, [pending]);

  return (
    <div
      className={"flex h-full w-full " + (isMobile ? "flex-col" : "flex-row")}
    >
      <div>
        <Navbar></Navbar>
      </div>
      <div className="flex flex-col h-full w-full overflow-auto">
        <Outlet />
      </div>
    </div>
  );
}

export default App;
