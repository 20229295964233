import {
  Button,
  Divider,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import React from "react";
import {
  HiArrowRight,
  HiClipboardCopy,
  HiFolder,
  HiUsers,
} from "react-icons/hi";
import QRCode from "react-qr-code";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Folder from "../../model/Folder";
import { getFolderUrl } from "../../utils/UrlUtils";
import { openManageAccessModal } from "./confirm";
import { useForm } from "@mantine/form";
import { UpdateFolderAccessDto } from "../../model/UpdateFolderAccessDto";
import FolderAccessInfoDto from "../../model/FolderAcessInfoDto";
import useGlobalState, { globalProfile } from "../../globalState";

export default function ShareModal(props: {
  folder: Folder;
  getFolderAccessInfo: (folderId: string) => Promise<FolderAccessInfoDto>;
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>;
}) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [profile, setProfile] = useGlobalState(globalProfile);

  const renderManageAccessButton = (folder: Folder) => {
    if (folder.accessLevel !== "write") return null;

    return (
      <>
        <Divider className="mt-3"></Divider>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row justify-start items-center">
            <Text className="text-center text-xs font-bold">
              Private folder, add users manually here
            </Text>
          </div>

          <Button
            className="flex w-full"
            variant="filled"
            leftSection={<HiUsers />}
            onClick={() =>
              openManageAccessModal(
                props.folder,
                props.getFolderAccessInfo,
                props.updateFolderAccess,
                isMobile
              )
            }
          >
            Manage access
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden">
      <div className="flex flex-row gap-2 justify-center items-center text-2xl mb-2">
        <HiFolder />
        {props.folder?.name}
      </div>

      <div className="max-w-52 m-auto mb-4 w-full h-full flex justify-center items-center p-3 bg-white">
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={getFolderUrl(props?.folder)}
          viewBox={`0 0 256 256`}
        />
      </div>

      <div className="flex flex-row justify-start items-center">
        <Text className="text-center text-xs">Share with</Text>
      </div>

      <div className="flex flex-row justify-start items-center gap-3 flex-wrap">
        <WhatsappShareButton url={getFolderUrl(props?.folder)}>
          <WhatsappIcon size={38} />
        </WhatsappShareButton>
        <TelegramShareButton url={getFolderUrl(props?.folder)}>
          <TelegramIcon size={38} />
        </TelegramShareButton>
        <FacebookShareButton url={getFolderUrl(props?.folder)}>
          <FacebookIcon size={38} />
        </FacebookShareButton>
        <TwitterShareButton url={getFolderUrl(props?.folder)}>
          <TwitterIcon size={38} />
        </TwitterShareButton>
        <EmailShareButton url={getFolderUrl(props?.folder)}>
          <EmailIcon size={38} />
        </EmailShareButton>
      </div>

      <div className="flex flex-row justify-start items-center">
        <Text className="text-center text-xs">Or share the link</Text>
      </div>

      <div className="flex flex-row gap-3 w-full justify-between items-end">
        <TextInput className="flex-1" value={getFolderUrl(props?.folder)} />
        <Button
          className="flex"
          variant="outline"
          aria-label="Copy"
          color="gray"
          onClick={() => {
            navigator.clipboard.writeText(getFolderUrl(props?.folder));
            if (!isMobile)
              notifications.show({
                message: "Link copied to clipboard",
                autoClose: 4000,
              });
          }}
          leftSection={<HiClipboardCopy />}
        >
          Copy
        </Button>
      </div>

      {renderManageAccessButton(props?.folder)}
    </div>
  );
}
