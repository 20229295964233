import {
  Button,
  Divider,
  LoadingOverlay,
  Select,
  TextInput,
  useComputedColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { MdSave } from "react-icons/md";
import Folder from "../../model/Folder";
import { useForm } from "@mantine/form";
import { HiArrowRight, HiFolder } from "react-icons/hi";
import { HiArrowDown } from "react-icons/hi";
import useApi from "../../services/useApi";
import {
  UpdateFolderAccessDto,
  UserAccessDto,
} from "../../model/UpdateFolderAccessDto";
import FolderAccessInfoDto from "../../model/FolderAcessInfoDto";

export default function ManageAccessModal(props: {
  folder: Folder;
  getFolderAccessInfo: (folderId: string) => Promise<FolderAccessInfoDto>;
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>;
}) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [users, setUsers] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const computedColorScheme = useComputedColorScheme("dark");

  useEffect(() => {
    setIsLoading(true);
    props.getFolderAccessInfo(props.folder.id).then((accessInfo) => {
      const elements = [];

      accessInfo.readUsers.forEach((readUser) => {
        elements.push({
          user: readUser.userEmail,
          folder: readUser.folder,
          permission: "Read",
        });
      });

      accessInfo.writeUsers.forEach((writeUser) => {
        elements.push({
          user: writeUser.userEmail,
          folder: writeUser.folder,
          permission: "Write",
        });
      });

      elements.sort((a, b) => a.user.localeCompare(b.user));

      setUsers(elements);
      setIsLoading(false);
    });
  }, [props.folder]);

  const form = useForm({
    initialValues: {
      email: "",
      permission: "Read",
    },
    clearInputErrorOnChange: true,
    //validateInputOnChange: true,
    validate: {
      email: (val) =>
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(val)
          ? null
          : "Invalid email",
    },
  });

  const addUser = () => {
    form.validate();
    if (!form.isValid()) return;
    form.reset();

    form.setFieldValue("email", "");
    users.push({
      user: form.values.email,
      permission: form.values.permission,
      isNew: true,
    });
    setUsers([...users]);
  };

  // TODO: test all edge cases. add originalPermission property to user
  const save = () => {
    const removeUsers: UserAccessDto[] = [];

    const addUsers: UserAccessDto[] = users
      .filter((u) => (u.isNew || u.isUpdated) && u.permission !== "None")
      .map((u) => {
        if (u.permission === "Read") {
          removeUsers.push({
            email: u.user,
            permission: "write",
          });
        }
        return {
          email: u.user,
          permission: u.permission.toLowerCase(),
        };
      });

    users.forEach((u) => {
      if (u.isUpdated && u.permission === "None") {
        removeUsers.push({
          email: u.user,
          permission: "read",
        });
        removeUsers.push({
          email: u.user,
          permission: "write",
        });
      }
    });

    // TODO: Refactor this
    /*props.folder.writeUsers = users
      .filter((u) => u.permission === "Write")
      .map((u) => u.user);
    props.folder.readUsers = users
      .filter((u) => u.permission === "Read")
      .map((u) => u.user);*/

    console.log({
      addUsers,
      removeUsers,
    });

    props.updateFolderAccess(props.folder.id, { addUsers, removeUsers });
  };

  const getRowClass = (row: any) => {
    const styles =
      "flex flex-row justify-between items-center text-sm border-t border-grey-800 ";

    if (row.isNew) {
      return styles + "bg-blue-400 bg-opacity-10";
    }

    if (row.permission === "None") {
      return styles + "bg-red-400 bg-opacity-10";
    }

    return styles;
  };

  const renderUsersPermissionsList = () => {
    return (
      <div className="flex flex-col max-w-full overflow-auto">
        <div className="flex flex-col overflow-auto">
          <form
            className="flex flex-col xs:flex-row"
            onSubmit={(e) => {
              e.preventDefault();
              addUser();
            }}
          >
            <div className="flex flex-1 flex-row justify-between items-start gap-3 mb-4 xs:mb-0 flex-wrap">
              <TextInput
                label="New user email"
                className="flex-1 w-full min-w-32"
                placeholder="example@email.com"
                value={form.values.email}
                onChange={(event) =>
                  form.setFieldValue("email", event.currentTarget.value)
                }
                radius="md"
                {...form.getInputProps("email")}
              />
              <Select
                label="Permissions"
                className="w-24 min-w-24"
                data={["Read", "Write"]}
                defaultValue={"Read"}
                allowDeselect={false}
                variant="filled"
                onChange={(value) => form.setFieldValue("permissions", value)}
                {...form.getInputProps("permissions")}
              />
            </div>

            <div
              className={
                "flex flex-row justify-center items-center gap-3 xs:ml-6 " +
                (!form.errors.email ? "xs:mt-5" : "")
              }
            >
              <Button
                type="submit"
                disabled={false}
                rightSection={<HiArrowDown></HiArrowDown>}
              >
                Invite user
              </Button>
            </div>
          </form>

          {users.length == 0 ? (
            <div className="flex flex-col gap-2 text-center mt-6 p-4 text-grey-600">
              <div className="text-xl">No users added yet</div>
              <div className="text-sm">
                Invite users to this folder by adding their email addresses
              </div>
            </div>
          ) : (
            <div
              className="flex flex-row justify-between items-center mt-6
                text-sm font-bold border-b border-grey-800"
            >
              <div className="w-full p-2">User</div>
              <div className="p-1 w-24 min-w-24">Permissions</div>
            </div>
          )}

          {users.map((row) => (
            <div className={getRowClass(row)} key={row.user}>
              <div className="w-full p-2 overflow-hidden text-wrap overflow-ellipsis">
                {row.user}
              </div>

              <div className="p-1 w-24 min-w-24">
                <Select
                  data={["Read", "Write", "None"]}
                  defaultValue={row?.permission ?? "Read"}
                  allowDeselect={false}
                  variant="unstyled"
                  disabled={row.user === props.folder.owner}
                  onChange={(value) => {
                    row.permission = value;
                    row.isUpdated = true;
                    setUsers([...users]);
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderContentOrLoader = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col gap-2 h-48">
          <LoadingOverlay
            visible={isLoading}
            zIndex={400}
            overlayProps={{
              blur: "xl",
              color: computedColorScheme === "dark" ? "black" : "black",
              opacity: computedColorScheme === "dark" ? "70%" : "30%",
            }}
            loaderProps={{ color: "blue", type: "bars", size: "xl" }}
          />
        </div>
      );
    }

    return (
      <>
        {renderUsersPermissionsList()}

        <div className="flex flex-row justify-end items-center">
          <Button
            variant="filled"
            leftSection={<MdSave />}
            onClick={() => save()}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  return <div className="flex flex-col gap-4">{renderContentOrLoader()}</div>;
}
