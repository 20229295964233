import React from "react";
import { HiFolder } from "react-icons/hi";
import Folder from "../../model/Folder";
import { Divider } from "@mantine/core";

export default function ManageAccessModalTitle(props: { folder: Folder }) {
  return (
    <div className="flex flex-col gap-2 justify-start items-start">
      <div className="text-md">Manage access</div>
      <div className="flex justify-start items-center text-grey-600 text-sm">
        <HiFolder className="flex itmes-center justify-center mr-1" />
        {props.folder?.name}
      </div>
    </div>
  );
}
