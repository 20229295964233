interface ContentTypeMap {
    [extension: string]: string;
}

const contentTypeMap: ContentTypeMap = {
    // Images
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'bmp': 'image/bmp',
    'webp': 'image/webp',

    // Documents
    'pdf': 'application/pdf',
    'doc': 'application/msword',
    'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'xls': 'application/vnd.ms-excel',
    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'ppt': 'application/vnd.ms-powerpoint',
    'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',

    // Audio/Video
    'mp3': 'audio/mpeg',
    'mp4': 'video/mp4',
    'avi': 'video/x-msvideo',
    'mov': 'video/quicktime',
    'wmv': 'video/x-ms-wmv',

    // Text
    'txt': 'text/plain',
    'csv': 'text/csv',

    // Archives
    'zip': 'application/zip',
    'rar': 'application/x-rar-compressed',

    // Default
    'default': 'application/octet-stream' // Fallback content type
};

export function getContentType(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase() || 'default';
    return contentTypeMap[extension] || contentTypeMap['default'];
}