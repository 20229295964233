import { ActionIcon, Button, CloseButton, Tabs } from "@mantine/core";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Map, {
  FullscreenControl,
  GeolocateControl,
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ScaleControl,
} from "react-map-gl/maplibre";
import useApi from "../../services/useApi";
import ControlPanel from "./ControlPanel";
import Pin from "./Pin";
import { useNavigate } from "react-router-dom";
import {
  HiArrowUp,
  HiArrowsExpand,
  HiLocationMarker,
  HiMap,
  HiNewspaper,
  HiOutlineArrowCircleUp,
  HiOutlinePlus,
  HiSearch,
} from "react-icons/hi";
import { IconButton } from "yet-another-react-lightbox";
import { MdSplitscreen } from "react-icons/md";
import { HiListBullet } from "react-icons/hi2";

export default function MapView() {
  const mapStyleJsons = {
    positron: "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json",
    dark: "https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json",
    voyager: "https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json",
    openStreet:
      "https://raw.githubusercontent.com/go2garret/maps/main/src/assets/json/openStreetMap.json",
    icgcDark:
      "https://geoserveis.icgc.cat/contextmaps/icgc_mapa_base_fosc.json",
  };

  const [popupInfo, setPopupInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { fetchFiles } = useApi();

  const mapRef = useRef<MapRef>();

  const mapPoints = [
    {
      name: "Vacaciones en Italia",
      date: "Julio 2023",
      folderId: "097dfdbe-bce9-40b4-9c6a-15af6fc4a1d5",
      latitude: 41.9028,
      longitude: 12.4964,
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBDqp8vwc0CNjBsrvPBx8ChPQxMV6YVwbr6A&usqp=CAU",
    },
    {
      name: "Asturias - Jesús y Aitor",
      date: "Junio 2024",
      folderId: "e791d7e8-ebb8-4e73-bc0d-b9e99c44c9ea",
      latitude: 43.23,
      longitude: -4.81,
      image:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/0/06/Urriellu_desde_el_Pozo_de_La_Oracion.jpg/408px-Urriellu_desde_el_Pozo_de_La_Oracion.jpg",
    },
  ];

  const pins = useMemo(
    () =>
      mapPoints.map((mapPoint, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={mapPoint.longitude}
          latitude={mapPoint.latitude}
          anchor="bottom"
          onClick={(e) => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            onSelectMarker(mapPoint);
          }}
        >
          <Pin selected={mapPoint?.name === popupInfo?.name} />
        </Marker>
      )),
    [popupInfo]
  );

  const onSelectMarker = useCallback((mapPoint) => {
    console.log(mapPoint);
    setPopupInfo(mapPoint);
    const { latitude, longitude } = mapPoint;
    mapRef.current?.flyTo({ center: [longitude, latitude], duration: 2000 });
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <Map
        reuseMaps
        initialViewState={{
          longitude: mapPoints[0].longitude,
          latitude: mapPoints[0].latitude,
          zoom: 3,
        }}
        style={{ width: "100%", height: "100%" }}
        mapStyle={mapStyleJsons.positron}
        ref={mapRef}
      >
        <GeolocateControl position="top-left" />
        <FullscreenControl position="top-left" />
        <NavigationControl position="top-left" />
        <ScaleControl position="bottom-left" />

        {pins}

        {/*popupInfo && (
          <Popup
            closeButton={false}
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
          >
            <div className="w-full h-full flex flex-col text-sm text-dark-600">
              <div className="flex justify-between items-center gap-2">
                {popupInfo.name}
                <CloseButton onClick={() => setPopupInfo(null)}></CloseButton>
              </div>
              <div className="text-xs mb-2">{popupInfo.date}</div>
              <div>
                <img width="100%" src={popupInfo.image} alt={popupInfo.name} />
              </div>
              <Button
                size="compact-sm"
                className="text-sm mt-2"
                onClick={() => navigate(`/browse/${popupInfo.folderId}`)}
              >
                Open
              </Button>
            </div>
          </Popup>
        )*/}
      </Map>

      <div className="border-t-2 border-grey-900">
        <div className="w-full mt-[-1rem] flex justify-center gap-2">
          <ActionIcon variant="outline" size="lg" radius="md" bg={"dark"}>
            <HiMap />
          </ActionIcon>
          <ActionIcon variant="default" size="lg" radius="md" bg={"dark"}>
            <HiSearch />
          </ActionIcon>
          <ActionIcon variant="default" size="lg" radius="md" bg={"dark"}>
            <MdSplitscreen />
          </ActionIcon>
        </div>

        <div className="actions p-2 flex gap-2">
          <Button size="xs" variant="light" leftSection={<HiOutlinePlus />}>
            Add marker
          </Button>
          <Button size="xs" variant="light" leftSection={<HiLocationMarker />}>
            Search marker
          </Button>
        </div>

        <ControlPanel mapPoints={mapPoints} onSelectMarker={onSelectMarker} />
      </div>
    </div>
  );
}
