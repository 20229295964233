import {
  Button,
  Divider,
  Select,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { MdSave } from "react-icons/md";
import Folder from "../../model/Folder";
import { useForm } from "@mantine/form";
import { HiArrowRight, HiFolder } from "react-icons/hi";
import { HiArrowDown } from "react-icons/hi";
import ManageFolderAccess from "../folder/ManageFolderAccess";
import { UpdateFolderAccessDto } from "../../model/UpdateFolderAccessDto";
import FolderAccessInfoDto from "../../model/FolderAcessInfoDto";

export default function ManageAccessModal(props: {
  folder: Folder;
  getFolderAccessInfo: (folderId: string) => Promise<FolderAccessInfoDto>;
  updateFolderAccess: (
    folderId: string,
    dto: UpdateFolderAccessDto
  ) => Promise<void>;
}) {
  return (
    <div className="flex flex-col gap-4">
      <ManageFolderAccess
        folder={props.folder}
        getFolderAccessInfo={props.getFolderAccessInfo}
        updateFolderAccess={props.updateFolderAccess}
      ></ManageFolderAccess>
    </div>
  );
}
