import {
  Button,
  Container,
  Divider,
  Drawer,
  Tooltip,
  UnstyledButton,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { FaRegImages } from "react-icons/fa6";
import {
  HiCog,
  HiFolder,
  HiHome,
  HiLightningBolt,
  HiMap,
  HiMenu,
  HiOutlineLogout,
  HiShieldExclamation,
  HiUser,
} from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { openLogoutModal } from "../modal/confirm";
import AppLogo from "../static/AppLogo";
import "./Navbar.css";
import { getAuth, signOut } from "firebase/auth";
import useGlobalState, { globalProfile, globalToken } from "../../globalState";

interface NavbarLinkProps {
  icon: typeof HiHome;
  color?: string;
  label: string;
  active?: boolean;
  onClick?: () => void;
}

export default function Navbar() {
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const { colorScheme, setColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const computedColorScheme = useComputedColorScheme("dark");

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [mobileMenuOpened, { toggle }] = useDisclosure();
  const [opened, { open, close }] = useDisclosure(false);
  const [profile, setProfile] = useGlobalState(globalProfile);
  const [token, setToken] = useGlobalState(globalToken);

  const location = useLocation();

  const navbarPrimary = [
    {
      path: "/home",
      icon: HiFolder,
      label: "Explorer",
      isActive: (path) =>
        path.startsWith("/home") || path.startsWith("/browse"),
      onClick: () => navigate("/home"),
    },
    //{ icon: HiMap, label: "Map", onClick: () => navigate("/map") },
    /*{
      path: "/account",
      icon: HiUser,
      label: "Account",
      onClick: () => navigate("/account"),
    },*/
  ];

  const navbarSecondary = [
    /*{
      icon: HiLightningBolt,
      label: "Upgrade account",
      color: colorScheme === "dark" ? "gold" : "goldenrod",
      onClick: () => console.log("upgrade account"),
    },*/ // TODO: add upgrade account page
    /*{
      icon: HiShieldExclamation,
      label: "Admin",
      color:
        colorScheme === "dark"
          ? "var(--mantine-color-orange-7)"
          : "var(--mantine-color-orange-6)",
      onClick: () => navigate("/admin"),
    },*/ // TODO: add admin page
    {
      path: "/settings",
      icon: HiCog,
      label: "Settings",
      onClick: () => navigate("/settings"),
    },
    {
      icon: HiOutlineLogout,
      label: "Logout",
      onClick: () =>
        openLogoutModal(() => {
          signOut(getAuth()).then(() => {
            setProfile(null);
            setToken(null);
            navigate("/login");
          });
        }),
    },
  ];

  // Remove upgrade account action if environment is private
  /*const isPrivateEnv = process.env.REACT_APP_ENVIRONMENT === "private";
  if (isPrivateEnv) {
    navbarSecondary.splice(0, 1);
  }*/

  const getIconColor = (color: string) => {
    if (color) return color;
    return colorScheme === "dark"
      ? "var(--mantine-color-dark-2)"
      : "var(--mantine-color-dark-2)";
  };

  const NavbarLink = ({
    icon: Icon,
    color,
    label,
    active,
    onClick,
  }: NavbarLinkProps) => {
    return (
      <Tooltip
        disabled={isMobile}
        label={label}
        position="right"
        withArrow
        arrowOffset={5}
        arrowSize={4}
        color="var(--mantine-color-dark-9)"
        transitionProps={{ duration: 200, transition: "fade" }}
      >
        <UnstyledButton
          onClick={onClick}
          className={
            "flex flex-row" +
            " " +
            (computedColorScheme === "dark"
              ? "link link--dark"
              : "link link--light")
          }
          p={16}
          data-active={active || undefined}
        >
          <div className="flex flex-row justify-start items-center">
            <Icon
              className="flex w-5 h-5"
              style={{
                color: getIconColor(color),
              }}
            />
            {isMobile ? (
              <Container hidden={!isMobile} className="flex flex-1">
                {label}
              </Container>
            ) : (
              ""
            )}
          </div>
        </UnstyledButton>
      </Tooltip>
    );
  };

  const links = navbarPrimary.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={
        link.path === location.pathname ||
        (link.isActive && link.isActive(location.pathname))
      }
      onClick={() => {
        if (isMobile) {
          toggle();
        }
        setActive(index);
        link.onClick();
      }}
    />
  ));

  const actions = navbarSecondary.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      onClick={() => {
        if (isMobile) {
          toggle();
        }
        link.onClick();
      }}
    />
  ));

  const getNavbarStyle = () => {
    const baseStyle = isMobile
      ? "flex flex-row justify-start items-center h-14 min-h-14 border-b-2 "
      : "flex flex-col w-full h-full justify-between border-r-2 ";
    const borderStyle =
      computedColorScheme === "dark"
        ? "border-[var(--mantine-color-dark-7)]"
        : "";

    return baseStyle + borderStyle;
  };

  return (
    <>
      <Container
        visibleFrom="xs"
        p={0}
        bg={
          computedColorScheme === "dark"
            ? "#111212"
            : "var(--mantine-color-gray-2)"
        }
        className={getNavbarStyle()}
      >
        <div className="flex flex-col">
          <div className="flex flex-col">{links}</div>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-col">{actions}</div>
        </div>
      </Container>

      <Container
        hiddenFrom="xs"
        bg={computedColorScheme === "dark" ? "#111212" : "#111212"}
        className={getNavbarStyle()}
      >
        <div>
          <div
            onClick={toggle}
            className="flex
            hover:bg-grey-900 active:bg-dark-999 cursor-pointer
            rounded-xl p-2 ml-[-0.5rem]"
            data-active={active || undefined}
          >
            <HiMenu className="w-5 h-5 text-grey-400" />
          </div>
          <Drawer
            opened={isMobile && mobileMenuOpened}
            onClose={toggle}
            title={
              <div className="flex items-center gap-1 text-xl">
                <AppLogo size={30}></AppLogo>
                <h1 className="h-full">{process.env.REACT_APP_APPNAME}</h1>
              </div>
            }
          >
            <div className="flex flex-col">
              <div className="flex flex-col">{links}</div>
            </div>
            <Divider my={18}></Divider>
            <div className="flex flex-col">
              <div className="flex flex-col">{actions}</div>
            </div>
          </Drawer>
        </div>
      </Container>
    </>
  );
}
